import axios from 'axios'
import showToastify from '../utils/toastify';
import IMask from 'imask';

export default function mentoriaForm() {
	const contatoFormSubmit = document.querySelector('#form-mentorias')

	const element = document.querySelector('#form-mentorias input[name=contato]')
	const maskOptions = {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	}
	const mask = IMask(element, maskOptions)

	contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
		event.preventDefault();
		disabledBtn()
		const form_data = {
			nome: document.querySelector('#form-mentorias input[name=nome]').value,
			email: document.querySelector('#form-mentorias input[name=email]').value,
			contato: document.querySelector('#form-mentorias input[name=contato]').value,
			//mentoria_interesse: document.querySelector('#form-mentorias select[name=mentoria_interesse]').value,
			mensagem: document.querySelector('#form-mentorias textarea[name=mensagem]').value
		}
		console.log(form_data);
		const url = "/formulario_mentoria/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));
	});

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector("#form-mentorias button[type=submit]")

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.innerHTML = 'Enviando ...'
		btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.innerHTML = 'Enviar mensagem'
		btnSubmit.classList.remove("disabled")
	}
}