import axios from 'axios'
import showToastify from '../utils/toastify';
import IMask from 'imask';

export default function contatoForm() {
	const contatoFormSubmit = document.querySelector('#form-contato')

	const element = document.querySelector('#form-contato input[name=contato]')
	const maskOptions = {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	}
	const mask = IMask(element, maskOptions)


	contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
		event.preventDefault();
		disabledBtn()
		const form_data = {
			nome: document.querySelector('#form-contato input[name=nome]').value,
			email: document.querySelector('#form-contato input[name=email]').value,
			assunto: document.querySelector('#form-contato input[name=assunto]').value,
			contato: document.querySelector('#form-contato input[name=contato]').value,
			mensagem: document.querySelector('#form-contato textarea[name=mensagem]').value
		}
		console.log(form_data);
		const url = "/formulario_contato/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));
	});

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector("#form-contato button[type=submit]")

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.innerHTML = 'Enviando ...'
		btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.innerHTML = 'Enviar mensagem'
		btnSubmit.classList.remove("disabled")
	}

	function linksTarget() {
		const links = document.querySelectorAll('.contato-editor-js  a')
		if (links) {
			links.forEach(link => {
				link.setAttribute("target", "_blank");
			});
		}
	}

	linksTarget()
}