import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import Swiper from "swiper"
import EditorJs from './modules/editorJsConvert';
import page_treinamentos from './pages/page_treinamentos';
import page_mentorias from './pages/page_mentorias';
import MenuFunc from './modules/menu';
import contatoForm from './modules/contato';
import page_palestra from './pages/page_palestra';
import page_gustavo_borges from './pages/page_gustavo_borges';
import gsapMentoria from './modules/gsapMentoria';
import mentoriaForm from './modules/mentoriasForm';
import modalBasico from './modules/modal-basico';
import palestrasForm from './modules/palestrasForm';
import treinamentosForm from './modules/treinamentosForm';
import newsletterForm from './modules/newsletterForm';
import initCounterUp from './modules/counterUp';

let swiper_galeria_01 = new Swiper(".swiper_galeria_01", {
  slidesPerView: 'auto',
  loop: true,
  allowTouchMove: false,
  alowtouch: false,
  speed: 7000,

  breakpoints: {
    220: {
      slidesPerView: 3,
      spaceBetween: 8,
    },

    1024: {
      slidesPerView: 3.4,
      spaceBetween: 4,
    },
  },

  autoplay: {
    delay: 1,
    pauseOnMouseEnter: true,
    disableOnInteraction: false
  },
});

let swiper_galeria_02 = new Swiper(".swiper_galeria_02", {
  slidesPerView: 'auto',
  loop: true,
  allowTouchMove: false,
  alowtouch: false,
  speed: 7000,

  breakpoints: {
    220: {
      slidesPerView: 3,
      spaceBetween: 8,
    },

    1024: {
      slidesPerView: 3.4,
      spaceBetween: 4,
    },
  },

  autoplay: {
    delay: 1,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
    reverseDirection: true,
  },
});

let swiper_confianca_banner = new Swiper(".swiper_confianca_banner", {
  slidesPerView: 'auto',
  spaceBetween: 16,

  navigation: {
    nextEl: ".swiper_confianca_banner .button-next",
    prevEl: ".swiper_confianca_banner .button-prev",
  },

  pagination: {
    el: ".swiper_confianca_banner .swiper-pagination-aprender",
    clickable: true,
    type: "bullets"
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
});

let swiper_linkdin_banner = new Swiper(".swiper_linkdin_banner", {
  freeMode: true,
  watchSlidesProgress: true,

  navigation: {
    nextEl: ".swiper_linkdin_banner .button-next",
    prevEl: ".swiper_linkdin_banner .button-prev",
  },

  pagination: {
    el: ".swiper_linkdin_banner .swiper-pagination-aprender",
    clickable: true,
    type: "bullets"
  },
  breakpoints: {
    220: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },

    1024: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
});

let swiper_carreira = new Swiper(".swiper_carreira", {
  freeMode: true,
  watchSlidesProgress: true,
  slidesPerView: 1,
  navigation: {
    nextEl: ".swiper_carreira .button-next",
    prevEl: ".swiper_carreira .button-prev",
  },

  pagination: {
    el: ".swiper_carreira .swiper-pagination-aprender",
    clickable: true,
    type: "bullets"
  },
});

const pageContato = document.querySelector('#Contato')
const pageMentorias = document.querySelector('#mentorias')
const pageTreinamentos = document.querySelector('#Treinamentos')
const pagePalestra = document.querySelector('#palestra')
const pageHome = document.querySelector('#Home')
// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
MenuFunc()
// Pages

EditorJs()

PageHome()
page_treinamentos()
page_mentorias()
page_palestra()
page_gustavo_borges()
PageLogin()?.init()

if (pageContato) {
  contatoForm()
}

if (pageMentorias) {
  mentoriaForm()
}

if (pageTreinamentos) {
  treinamentosForm()
}

if (pagePalestra) {
  palestrasForm()
}

if (pageHome) {
  palestrasForm()
  treinamentosForm()
}



newsletterForm()
initCounterUp()
modalBasico()
gsapMentoria()
// utils logs
logs() 
